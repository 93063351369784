.container {
  width: 100%;
  overflow: hidden;
}

.box-type {
  width: 6.94rem;
  margin: .3rem auto 0;
}

.game-type {
  grid-gap: .26rem;
  grid-template-columns: 2.16rem 2.16rem 2.16rem;
  width: 100%;
  display: grid;
}

.game-type > .item {
  background-color: #e9f6fd;
  border: .04rem solid #292f52;
  border-radius: .2rem;
  flex-direction: row;
  align-items: center;
  width: 2.16rem;
  height: 1rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 1px 3px #0000004d;
}

.game-type > .item img {
  border-radius: .1rem;
  width: .72rem;
  height: .72rem;
  margin-left: .14rem;
}

.game-type > .item > span {
  color: #020202;
  margin-left: .14rem;
  font-size: .2rem;
  font-weight: bold;
  display: block;
}

.box-top {
  width: 6.94rem;
  margin: .3rem auto 0;
}

.box-top .scorll-wrap {
  overflow-x: scroll;
}

.box-top .scorll-wrap .item-top {
  flex-direction: row;
  align-items: center;
  width: 8.56rem;
  display: flex;
}

.box-top .scorll-wrap .item-top .top1 {
  background-color: #e9f6fd;
  border: .04rem solid #292f52;
  border-radius: .2rem;
  width: 4.28rem;
  min-height: 6.7rem;
  margin-right: .2rem;
  padding: .28rem;
}

.box-top .scorll-wrap .item-top .top1 .top-tile {
  color: #292f52;
  width: 100%;
  margin-bottom: .3rem;
  font-size: .34rem;
}

.box-top .scorll-wrap .item-top .top1 .top-con {
  width: 4.28rem;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items {
  width: 100%;
  margin-bottom: .28rem;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items > a {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items > a .items-idx {
  color: #292f52;
  text-align: right;
  width: .4rem;
  font-size: .34rem;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items > a .imgs {
  border: .04rem solid #292f52;
  border-radius: .2rem;
  width: .84rem;
  height: .84rem;
  margin-left: .2rem;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items > a .g-info {
  flex-direction: column;
  margin-left: .2rem;
  display: flex;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items > a .g-info > p {
  color: #292f52;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 2.12rem;
  font-size: .28rem;
  overflow: hidden;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items > a .star {
  flex-direction: row;
  align-items: center;
  margin-top: .1rem;
  display: flex;
}

.box-top .scorll-wrap .item-top .top1 .top-con > .items > a .star > .icon-star {
  width: .28rem;
  height: .28rem;
  margin-right: .04rem;
}

.list-search {
  min-height: 56vh;
}

.box-temp {
  flex-flow: wrap;
  width: 6.94rem;
  margin: 0 auto;
  display: flex;
}

.box-temp p {
  background-color: #00000080;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.box-temp p > .txt {
  color: #fff;
  height: .36rem;
  font-size: .2rem;
  line-height: .36rem;
}

.box-temp-1 {
  flex-flow: wrap;
  width: 100%;
  display: flex;
}

.box-temp-1 .item {
  background: url("default.685270e4.png") center / 100% no-repeat;
  border: .04rem solid #292f52;
  border-radius: .2rem;
  width: 2.14rem;
  height: 2.14rem;
  margin-bottom: .28rem;
  margin-left: .24rem;
  position: relative;
  overflow: hidden;
}

.box-temp-1 .item:nth-child(3n+1) {
  margin-left: 0;
}

.box-temp-1 .item p {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: .44rem;
  margin: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.box-temp-1 .item a {
  width: 2.14rem;
  height: 2.14rem;
  display: block;
}

.box-temp-1 .item a img {
  width: 2.14rem;
  height: 2.14rem;
}

.box-temp-2 {
  flex-flow: wrap;
  width: 100%;
  display: flex;
}

.box-temp-2 .item {
  border: .04rem solid #292f52;
  border-radius: .2rem;
  width: 1.62rem;
  height: 1.62rem;
  margin-bottom: .28rem;
  margin-left: .14rem;
  position: relative;
  overflow: hidden;
}

.box-temp-2 .item:nth-child(3n+1) {
  margin-left: 0;
}

.box-temp-2 .item p {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: .44rem;
  margin: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.box-temp-2 .item a {
  width: 1.62rem;
  height: 1.62rem;
  display: block;
}

.box-temp-2 .item a img {
  width: 1.62rem;
  height: 1.62rem;
}

.box1 {
  width: 6.94rem;
  margin: 0 auto;
}

.box-list1 {
  flex-flow: wrap;
  display: flex;
}

.box-list1 .items {
  width: 3.34rem;
  margin-bottom: .3rem;
  margin-left: .26rem;
}

.box-list1 .items:nth-child(odd) {
  margin-left: 0;
}

.box-list1 .items > a {
  display: block;
}

.box-list1 .items img {
  border: .04rem solid #292f52;
  border-radius: .2rem;
  width: 3.34rem;
  height: 2rem;
  overflow: hidden;
}

.box-list1 .items p {
  text-align: center;
  color: #292f52;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  height: .4rem;
  font-size: .28rem;
  line-height: .4rem;
  overflow: hidden;
}

.popular {
  width: 100%;
}

.popular .title {
  color: #000;
  width: 100%;
  margin: 0 auto .3rem;
  padding-left: .16rem;
  font-size: .4rem;
}

.recent-index {
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: scroll;
}

.recent-index > .item {
  width: 1.26rem;
  margin-right: .2rem;
  position: relative;
}

.recent-index > .item > a {
  flex-direction: column;
  width: 1.26rem;
  display: flex;
}

.recent-index > .item > a img {
  border: .04rem solid #292f52;
  border-radius: .1rem;
  width: 1.26rem;
  height: 1.26rem;
}

.recent-index > .item > a p {
  text-align: center;
  color: #292f52;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  height: .4rem;
  margin-top: .14rem;
  font-size: .28rem;
  line-height: .4rem;
  overflow: hidden;
}

.recent-index > .item > a p > .txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.recent-list > .item {
  margin-top: 0;
  margin-bottom: .3rem;
}

.index-intro {
  background-color: #e9f6fd;
  border: .04rem solid #292f52;
  border-radius: .2rem;
  width: 7.22rem;
  margin: 0 auto;
  padding: .4rem .24rem .3rem;
}

.index-intro > h3 {
  color: #292f52;
  text-align: center;
  width: 100%;
  font-size: .4rem;
}

.index-intro p {
  color: #292f52;
  text-indent: .4rem;
  text-align: center;
  margin: .4rem auto;
  font-size: .26rem;
  line-height: .4rem;
}

.index-intro p:last-child {
  margin-bottom: 0;
}

.swiper {
  border-radius: .3rem;
  width: 100%;
  height: 4.36rem;
  position: relative;
}

.swiper-slide {
  justify-content: center;
  align-items: center;
  width: 6.94rem;
  transition: all .3s;
  display: flex;
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  z-index: 2;
  transform: scale(1);
}

.popularWrapper .swiper-slide:before {
  content: "";
  width: .6rem;
  height: .6rem;
  position: absolute;
  top: .1rem;
  left: .1rem;
}

.swiper-slide a {
  border: .04rem solid #292f52;
  border-radius: .3rem;
  width: 6.94rem;
  display: inline-block;
}

.swiper-slide a img {
  object-fit: cover;
  border-radius: .3rem;
  width: 6.9rem;
  height: 4.22rem;
  display: block;
}

.slide-num {
  z-index: 2;
  color: #fff;
  text-align: center;
  background-color: #000000bf;
  border-radius: .2rem;
  width: .7rem;
  height: .7rem;
  font-size: .32rem;
  line-height: .7rem;
  position: absolute;
  bottom: .3rem;
  right: .3rem;
}

/*# sourceMappingURL=search.7df68721.css.map */
