@import url("../css/common.css");

.container{
    width:100%;
    overflow: hidden;
}

.box-type{
    width:6.94rem;
    margin:0.3rem auto 0;
}
.game-type{
    width:100%;
    display: grid;
    grid-template-columns:2.16rem 2.16rem 2.16rem;
    grid-gap:0.26rem;
   
    &>.item{
        width:2.16rem;
        height:1rem;
        background-color: #E9F6FD;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
        display: flex;
        flex-direction: row;
        align-items: center;

        overflow: hidden;
        border:0.04rem solid #292F52;
        border-radius: 0.2rem;

        img{
            width:0.72rem;
            height:0.72rem;
            margin-left:0.14rem;
            border-radius: 0.1rem;
        }
        &>span{
            display: block;
            color:#020202;
            font-size:0.2rem;
            margin-left:0.14rem;
            font-weight: bold;
        }
    }
}
.box-top{
    width:6.94rem;
    margin:0.3rem auto 0;
    .scorll-wrap{
        overflow-x: scroll;
        .item-top{
            width:8.56rem;
            display: flex;
            flex-direction: row;
            align-items: center;

            .top1{
                width:4.28rem;
                min-height: 6.7rem;
                background-color: #E9F6FD;
                border:0.04rem solid #292F52;
                border-radius: 0.2rem;
                margin-right:0.2rem;
                padding:0.28rem;
                .top-tile{
                    width:100%;
                    font-size:0.34rem;
                    color:#292F52;
                    margin-bottom: 0.3rem;
                }
                .top-con{
                    width:4.28rem;
                    &>.items{
                        width:100%;
                        margin-bottom:0.28rem;
                        &>a{
                            width:100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            .items-idx{
                                color:#292F52;
                                font-size:0.34rem;
                                width:0.4rem;
                                text-align: right;
                            }
                            .imgs{
                                width:0.84rem;
                                height:0.84rem;
                                border:0.04rem solid #292F52;
                                border-radius: 0.2rem;
                                margin-left:0.2rem;
                            }
                            .g-info{
                                display:flex;
                                flex-direction: column;
                                margin-left:0.2rem;
                                &>p{
                                    width:2.12rem;
                                    font-size:0.28rem;
                                    color:#292F52;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            .star{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin-top:0.1rem;
                                &>.icon-star{
                                    width:0.28rem;
                                    height:0.28rem;
                                    margin-right:0.04rem;
                                }
                            }
                            
                        }
                    }
                    
                }

            }
        }
    }

}



.list-search{
    min-height: 56vh;
}
.box-temp{
    width:6.94rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:0 auto;
    p{
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &>.txt{
            height:0.36rem;
            line-height: 0.36rem;
            color:#fff;
            font-size:0.2rem;
        }
    }
}
.box-temp-1{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.box-temp-1 .item{
    width:2.14rem;
    height:2.14rem;
    margin-left:0.24rem;
    margin-bottom:0.28rem;
    position: relative;
    border:0.04rem solid #292F52;
    border-radius: 0.2rem;
    overflow: hidden;
    background: url("../images/default.png") center center no-repeat;
    background-size:100%;
    &:nth-child(3n+1){
        margin-left: 0;
    }
    p{
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        height:0.44rem;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0;
    }
}

.box-temp-1 .item a{
    display: block;
    width:2.14rem;
    height:2.14rem;
}
.box-temp-1 .item a img{
    width:2.14rem;
    height:2.14rem;
}


.box-temp-2{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.box-temp-2 .item{
    width:1.62rem;
    height:1.62rem;
    margin-left:0.14rem;
    margin-bottom:0.28rem;
    position: relative;
    border:0.04rem solid #292F52;
    border-radius: 0.2rem;
    overflow: hidden;
    &:nth-child(3n+1){
        margin-left: 0;
    }
    p{
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        height:0.44rem;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0;
    }
}

.box-temp-2 .item a{
    display: block;
    width:1.62rem;
    height:1.62rem;
}
.box-temp-2 .item a img{
    width:1.62rem;
    height:1.62rem;
}


.box1{
    width:6.94rem;
    margin:0 auto;
}
.box-list1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.box-list1 .items{ 
    width:3.34rem;
    margin-bottom:0.3rem;
    margin-left:0.26rem;
    &:nth-child(2n+1){
        margin-left:0;
    }
    &>a{
        display: block;
    }
    img{
        width:3.34rem;
        height:2rem;
        border:0.04rem solid #292F52;
        border-radius: 0.2rem;
        overflow: hidden;
    }
    p{
        width:100%;
        height:0.4rem;
        line-height:0.4rem;
        text-align: center;
        color:#292F52;
        font-size:0.28rem;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break:break-all;
    }
}

.popular{
    width:100%;
    .title{
        width:100%;
        font-size:0.4rem;
        color:#000;
        margin:0 auto;
        padding-left:0.16rem;
        margin-bottom: 0.3rem;
    }
}
.recent-index{
    width:100%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    &>.item{
        width:1.26rem;
        position: relative;
        margin-right:0.2rem;
        &>a{
            width:1.26rem;
            display: flex;
            flex-direction: column;
            img{
                width:1.26rem;
                height:1.26rem;
                border-radius: 0.1rem;
                border:0.04rem solid #292F52;
            }
            p{
                width:100%;
                height:0.4rem;
                line-height:0.4rem;
                text-align: center;
                color:#292F52;
                font-size:0.28rem;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break:break-all;
                margin-top:0.14rem;
                &>.txt{
                    width:100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
.recent-list{
    &>.item{
        margin-top:0;
        margin-bottom: 0.3rem;
    }
}
.index-intro{
    width:7.22rem;
    padding:0.4rem 0.24rem 0.3rem;
    background-color: #E9F6FD;
    border:0.04rem solid #292F52;
    margin:0 auto;
    border-radius: 0.2rem;
    &>h3{
        font-size:0.4rem;
        color:#292F52;
        width: 100%;
        text-align: center;
    }
    p{
        font-size:0.26rem;
        color:#292F52;
        text-indent: 0.4rem;
        margin:0.4rem auto;
        line-height: 0.4rem;
        text-align: center;
        &:last-child{
            margin-bottom: 0;
        }
    }
}


.swiper {
    width: 100%;
    height:4.36rem;
    border-radius: 0.3rem;
    position: relative;
}
.swiper-slide {
    width: 6.94rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
  }
  .swiper-slide-active,.swiper-slide-duplicate-active{
    transform: scale(1);
    z-index: 2;
  }
.popularWrapper .swiper-slide::before{
    content: "";
    position: absolute;
    width:0.6rem;
    height:0.6rem;
    top:0.1rem;
    left:0.1rem;

}
.swiper-slide a{
    width: 6.94rem;
    display: inline-block;
    border:0.04rem solid #292F52;
    border-radius: 0.3rem;;
}
.swiper-slide a img{
    display: block;
    width: 6.9rem;
    height:4.22rem;
    object-fit: cover;
    border-radius: 0.3rem;
}
.slide-num{
    width:0.7rem;
    height:0.7rem;
    line-height: 0.7rem;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    right:0.3rem;
    bottom:0.3rem;
    z-index: 2;
    border-radius:0.2rem;
    font-size:0.32rem;
    color:#fff;
    text-align: center;
}
